<template lang="pug">
.game(:style="{ marginTop: $store.state.navbarHeight + 'px' }")
  v-container
    v-row.justify-center.mt-12
      v-card.text-center.pa-1(
        width="300",
        color="grey lighten-3",
        elevation="0"
      )
        .text-subtitle {{ formatDate(gameData.date) }}
    v-row
      v-col.d-flex.justify-center(col="12")
        img.home_team.clickable.temp_logo_hidden(
          @click="teamClick(true)",
          v-if="gameData.home_team_logo",
          :src="gameData.home_team_logo.image",
          height="120",
          width="120",
          contain,
          style="flex-basis: 120px"
        )
        .scores.d-flex.justify-center.align-center
          .home_score.text-center
            h5.font-weight-bold(
              style="font-size: 40px",
              :style="[gameData.home_team_pts > gameData.away_team_pts ? { color: '#ff6050' } : {}]"
            ) {{ gameData.home_team_pts }}
            span.clickable.text-body-2(@click="teamClick(true)") {{ gameData.home_team_name }}

          span.vs.font-weight-bold.font-italic(style="font-family: Helvetica") VS
          .away_score.text-center
            h5.font-weight-bold(
              style="font-size: 40px",
              :style="[gameData.away_team_pts > gameData.home_team_pts ? { color: '#ff6050' } : {}]"
            ) {{ gameData.away_team_pts }}
            span.clickable.text-body-2(@click="teamClick(false)") {{ gameData.away_team_name }}
        img.away_team.clickable.temp_logo_hidden(
          @click="teamClick(false)",
          v-if="gameData.away_team_logo",
          :src="gameData.away_team_logo.image",
          height="120",
          width="120",
          contain,
          style="flex-basis: 120px"
        )
    v-row.table_row(style="margin-top: 100px")
      v-col.table.table_col(cols="12")
        table
          thead
            tr.bottom_head
              sticky-column.bdl.bdr2(
                style="width: 150px; min-width: 150px",
                title="隊伍",
                :offset="0"
              )
              template(v-if="gameData")
                th.bdr(v-for="index in gameData.quarter_count") {{ '第' + index + '節' }}
              template(v-if="gameData.ot_count > 0")
                th.bdr(v-for="index in gameData.ot_count") {{ 'OT' + index }}
              th 總計
          tbody
            tr
              sticky-column.bdl.bdr2(
                style="width: 150px; min-width: 150px",
                :title="gameData.home_team_name",
                :offset="0"
              )
              td.bdr.hover(
                v-for="index in gameData.quarter_count",
                @click="dataClick(gameData.home_team_id, gameData.home_team_name, index)",
                style="cursor: pointer"
              ) {{ gameData.home_team_quarter_pts[index - 1] }}
              template(v-if="gameData.ot_count > 0")
                td.bdr.hover(
                  v-for="index in gameData.ot_count",
                  @click="dataClick(gameData.home_team_id, gameData.home_team_name, index + gameData.quarter_count)",
                  style="cursor: pointer"
                ) {{ gameData.home_team_quarter_pts[gameData.quarter_count + index - 1] }}
              td.bdr {{ gameData.home_team_pts }}
            tr
              sticky-column.bdl.bdr2(
                style="width: 150px; min-width: 150px",
                :title="gameData.away_team_name",
                :offset="0"
              )
              td.bdr.hover(
                v-for="index in gameData.quarter_count",
                @click="dataClick(gameData.away_team_id, gameData.away_team_name, index)",
                style="cursor: pointer"
              ) {{ gameData.away_team_quarter_pts[index - 1] }}
              template(v-if="gameData.ot_count > 0")
                td.bdr.hover(
                  v-for="index in gameData.ot_count",
                  @click="dataClick(gameData.away_team_id, gameData.away_team_name, index + gameData.quarter_count)",
                  style="cursor: pointer"
                ) {{ gameData.away_team_quarter_pts[gameData.quarter_count + index - 1] }}
              td.bdr {{ gameData.away_team_pts }}
  v-container.tab_container(style="padding: 0", fluid)
    v-tabs(
      show-arrows,
      background-color="transparent",
      ripple="false",
      v-model="tab",
      centered
    )
      v-tabs-slider(color="primary")

      v-tab(
        @click="toPage(index)",
        :ripple="false",
        v-for="(item, index) in tabList",
        :key="index"
      ) {{ item }}
  v-main
    transition(name="page", mode="out-in")
      router-view
  custom-footer
  v-dialog.position-relative(
    v-model="isDialogShow",
    style="background: white; height: 100%; width: 810px"
  )
    .close_btn_container
      v-icon.close_btn(@click="isDialogShow = false", large, color="black") mdi-close
    v-container.position-relative.content_container(ref="content")
      v-row(justify="center")
        .uvideo
          youtube(
            ref="youtube",
            @ready="onYTReady",
            @ended="onYTEnd",
            resize,
            fitParent,
            nocookie,
            style="width: 100%; height: 100%; position: absolute; top: 0; left: 0"
          )

          .yt_cover(v-if="coverYT")
            v-btn.play_all.cover(
              @click="playAllClick",
              rounded="",
              color="#487aa4",
              dark,
              v-ripple="false"
            )
              v-icon(left="")
                | mdi-play
              | 播放全部
      v-row(justify="space-between")
        .reference
          //- span.d-block 影片出處：
          //-   a.text_primary(
          //-     href="https://www.youtube.com/c/ssutv/videos",
          //-     target="_blank"
          //-   ) https://www.youtube.com/c/ssutv/videos
          //- span 影片版權皆歸中華民國大專院校體育總會及轉播單位所有
        v-btn.play_all(
          @click="playAllClick",
          rounded="",
          color="#487aa4",
          dark,
          v-ripple="false"
        )
          v-icon(left="")
            | mdi-play
          | 播放全部
      v-row
        span.player_name_team {{ dialogName }} | {{ dialogTeam }}
        .play_table
          .play_head
            .head.play_play 比賽
            .head.play_play 順序
            .head.play_play 節
            .head.play_play
            .head.play_time 時間
            .head.play_player 球員
            .head.play_team 隊伍
            .head.play_event 事件
            .head.play_feedback 問題回報
          .play_content
            .play_item(
              :class="{ selected: currentPlayIndex == index }",
              v-for="(play, index) in playList",
              :key="index"
            )
              .content.play_play {{ play.game__name }}
              .content.play_play {{ index + 1 }}
              .content.play_play {{ play.quarter }}
              .content.play_play
                v-icon.play(x-small, color="white") mdi-play
              .content.play_time {{ play.video_start_at }}
              .content.play_player {{ play.season_player__name }}
              .content.play_team {{ dialogTeam }}
              .content.play_event {{ dialogEvent }}
              .content.play_feedback
                v-icon(small) mdi-alert-octagon
              .play_btn(@click="onPlayItemClick(play, index)")
              .feedback_btn(@click="onFeedbackBtnClick(play)")
  dialog-success(
    :title="dialogSuccessData.title",
    @closeSuccessDialog="closeSuccessDialog",
    :dialogSuccess="dialogSuccessShow"
  )
    template(#content)
      p {{ dialogSuccessData.text }}
  dialog-loading(:dialogLoading="dialogLoadingShow")
    template(#content)
      p {{ dialogLoadingData.text }}
  dialog-feedback(
    :dialogFeedback="dialogFeedbackShow",
    :play="feedBackPlay",
    @closeFeedbackDialog="closeFeedbackDialog"
  )
  dialog-login(
    :dialogLogin="dialogLoginShow",
    @closeLoginDialog="closeLoginDialog",
    @loginSuccess="loginSuccess"
  )
</template>

<script>
import CustomFooter from "@/components/common/Footer";
import StickyColumn from "@/components/common/Table/StickyColumn";
import { getGameData } from "@/api/game";
import { getPlayData, getPlayList } from "@/api/play";
import moment from "moment";
import "moment/locale/zh-tw";
import DialogLoading from "@/components/dialog/DialogLoading";
import DialogSuccess from "@/components/dialog/DialogSuccess";
import DialogFeedback from "@/components/dialog/DialogFeedback";
import DialogLogin from "@/components/dialog/DialogLogin";

export default {
  name: "LeagueGame",
  metaInfo() {
    return {
      title: this.metaData.title,
      meta: [
        {
          name: "description",
          content: this.metaData.description,
        },
        {
          name: "keywords",
          content: this.metaData.keywords,
        },
        { property: "og:site_name", content: this.metaData.siteTitle },
        {
          property: "og:title",
          content: this.metaData.title,
        },
        {
          property: "og:description",
          content: this.metaData.description,
        },
        { name: "og:url", content: this.metaData.url },
        { name: "og:image", content: this.metaData.image },
        { name: "smo_type", content: this.metaData.smoType },
      ],
    };
  },
  components: {
    StickyColumn,
    CustomFooter,
    DialogSuccess,
    DialogLoading,
    DialogFeedback,
    DialogLogin,
  },
  data() {
    return {
      league: this.$route.params.league,
      season: this.$route.params.season,
      gameID: this.$route.params.id,
      gameData: {},
      teamGameDataPoints: [],
      tab: 0,
      tabList: ["Box Score", "進階數據"],

      isDialogShow: false,
      coverYT: true,
      playHeadList: ["球員", "隊伍", "事件", "時間", "播放"],
      isPlayAll: false,
      playAllIndex: 0,
      currentPlayIndex: null,
      dialogName: "",
      dialogTeam: "",
      dialogEvent: "",
      playList: [],

      feedBackPlay: null,

      dialogSuccessShow: false,
      dialogSuccessData: {
        title: "",
        text: "",
      },

      dialogLoadingShow: false,
      dialogLoadingData: {
        text: "",
      },
      dialogFeedbackShow: false,
      dialogLoginShow: false,
      //TODO: siteTitle
      metaData: {
        siteTitle: "籃球數據分析Game Changer",
        title: "籃球數據分析Game Changer",
        description:
          "提供台灣籃球完整資訊，包含中文字幕影音、精彩Highlights、文字轉播、最新消息、精彩圖輯、球隊與球員詳細數據、賽程比分、名家專欄等內容。",
        keywords: "Game changer,籃球數據分析,戰術分享,球員履歷",
        url: "https://www.gamechanger.tw/",
        image: "",
        smoType: "website",
      },

      quarter_count: 0,
      ot_count: 0,
    };
  },
  computed: {
    YTPlayer() {
      return this.$refs.youtube.player;
    },
  },
  watch: {
    isDialogShow() {
      if (!this.isDialogShow) {
        this.$refs.youtube.player.stopVideo();
        this.dialogName = "";
        this.dialogTeam = "";
        this.dialogEvent = "";
        this.isPlayAll = false;
        this.playAllIndex = 0;
        this.currentPlayIndex = null;
        this.playList = [];
        this.coverYT = true;
      }
    },
    "$route.name"() {
      this.checkTab();
    },
  },
  async created() {
    if (this.$route.name != null) {
      this.checkTab();
    }
    await this.getGameData();
  },
  methods: {
    async getGameData() {
      let temp = {
        game_id: this.gameID,
      };
      await getGameData(temp).then((response) => {
        this.gameData = response.data;
        this.quarter_count = this.gameData.quarter_count;
        this.metaData.title =
          this.gameData.home_team_name +
          "vs." +
          this.gameData.away_team_name +
          " | " +
          this.formatDate(this.gameData.date) +
          " | " +
          this.metaData.title;
      });
    },
    formatDate(date) {
      return moment(date).format("YYYY/MM/DD dddd");
    },
    toPage(index) {
      switch (index) {
        case 0:
          this.$router.push({ name: "LeagueGameBoxScore" });
          break;
        case 1:
          this.$router.push({ name: "LeagueGameAdvancedStatistics" });
          break;
      }
    },
    teamClick(isHomeTeam) {
      let id;

      if (isHomeTeam) {
        id = this.gameData.home_team__team_id;
      } else {
        id = this.gameData.away_team__team_id;
      }

      this.$router.push({
        name: "LeagueTeam",
        params: {
          league: this.league,
          season: this.season,
          id: id,
        },
      });
    },
    getPlayList(seasonTeamID, quarter) {
      this.showLoadingDialog("載入中...");

      let params = {
        game_id: this.gameID,
        season_team_id: seasonTeamID,
        group_by: "TEAM",
        quarter: quarter,
      };

      let data = {};

      getPlayList(params, data)
        .then((response) => {
          this.closeLoadingDialog();
          this.playList = response.data;
          console.log("this.playList=", this.playList);
          if (this.playList && this.playList.length > 0) {
            this.isDialogShow = true;
          }
        })
        .catch(() => {
          this.closeLoadingDialog();
        });
    },
    getPlayData(seasonTeamID, quarter) {
      this.showLoadingDialog("載入中...");
      let temp = {
        game_id: this.gameID,
        season_team_id: seasonTeamID,
        quarter: quarter,
      };
      getPlayData(temp)
        .then((response) => {
          this.closeLoadingDialog();
          this.playList = response.data;
          if (this.playList && this.playList.length > 0) {
            this.isDialogShow = true;
          }
        })
        .catch(() => {
          this.closeLoadingDialog();
        });
    },
    dataClick(seasonTeamID, seasonTeamName, quarter) {
      this.isPlayAll = false;
      this.playList = [];
      this.dialogName = seasonTeamName;
      this.dialogTeam = seasonTeamName;
      this.getPlayList(seasonTeamID, quarter);
    },
    playAllClick() {
      this.coverYT = false;
      this.isPlayAll = true;
      this.playAllIndex = 0;
      this.currentPlayIndex = this.playAllIndex;
      if (this.playList && this.playList.length > 0) {
        this.playVideo(this.playList[this.playAllIndex]);
      }
    },
    onYTReady() {
      if (this.playList && this.playList.length > 0) {
        this.YTPlayer.cueVideoById({
          videoId: this.playList[0].video,
        });
      }
    },
    onYTEnd(e) {
      if (this.isPlayAll && e.getVideoLoadedFraction() > 0) {
        if (this.playList && this.playList.length > 0) {
          if (this.playAllIndex + 1 < this.playList.length) {
            this.playAllIndex = this.playAllIndex + 1;

            this.currentPlayIndex = this.playAllIndex;

            this.playVideo(this.playList[this.playAllIndex]);
          } else {
            this.isPlayAll = false;
            this.playAllIndex = 0;
          }
        }
      }
    },
    onPlayItemClick(play, index) {
      this.coverYT = false;
      this.currentPlayIndex = index;
      this.playVideo(play);
    },
    onFeedbackBtnClick(play) {
      play.league = this.league;
      play.team = this.dialogTeam;
      this.feedBackPlay = play;
      this.showFeedbackDialog();
      // if (!this.$store.state.user.accessToken) {
      //   this.showLoginDialog();
      // } else {
      //   this.showFeedbackDialog();
      // }
    },
    playVideo(play) {
      this.scrollToTop();
      var startSeconds =
        play.ft_attempt > 0
          ? moment.duration(play.video_end_at).asSeconds() - 14
          : moment.duration(play.video_start_at).asSeconds();
      this.YTPlayer.loadVideoById({
        videoId: play.game__youtube_ids__0,
        startSeconds: startSeconds - play.video_record_duration,
        endSeconds: moment.duration(play.video_end_at).asSeconds() + 2,
      });
    },
    scrollToTop() {
      const c = this.$refs.content.scrollTop || document.body.scrollTop;
      if (c > 0) {
        window.requestAnimationFrame(this.scrollToTop);
        this.$refs.content.scrollTo(0, c - c / 10);
      }
    },
    checkTab() {
      if (this.$route.name == "LeagueGameBoxScore") {
        this.$nextTick(() => {
          this.tab = 0;
        });
      } else if (this.$route.name == "LeagueGameAdvancedStatistics") {
        this.$nextTick(() => {
          this.tab = 1;
        });
      }
    },
    loginSuccess() {
      this.closeLoginDialog();
    },
    showFeedbackDialog() {
      this.dialogFeedbackShow = true;
    },
    closeFeedbackDialog() {
      this.dialogFeedbackShow = false;
    },
    showLoginDialog() {
      this.dialogLoginShow = true;
    },
    closeLoginDialog() {
      this.dialogLoginShow = false;
    },
    showSuccessDialog(title, text) {
      this.dialogSuccessData.title = title;
      this.dialogSuccessData.text = text;
      this.dialogSuccessShow = true;
    },
    closeSuccessDialog() {
      this.dialogSuccessData.title = "";
      this.dialogSuccessData.text = "";
      this.dialogSuccessShow = false;
    },
    showLoadingDialog(text) {
      this.dialogLoadingData.text = text;
      this.dialogLoadingShow = true;
    },
    closeLoadingDialog() {
      this.dialogLoadingData.text = "";
      this.dialogLoadingShow = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/common/table";
@import "@/assets/style/common/dialog_play_video";
@import "@/assets/style/league/game";
</style>
